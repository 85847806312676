import React, { useState } from 'react';
import axios from 'axios';
import ProgressBar from './components/ProgressBar';

export default function App() {
  const [file, setFile] = useState(null);
  const [imageURL, setImageURL] = useState('');
  const [progress, setProgress] = useState('0%');

  const API_URL = 'https://api.konnect.dev/api/v2';

  const handleUploadImage = (e) => {
    e.preventDefault();
    if (e.target.files[0]) {
      if (e.target.files[0].size > 5000000) {
        alert('File too large!');
      } else {
        setFile(e.target.files[0]);
      }
    }
  };

  const submitImage = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', file);

    axios
      .post(`${API_URL}/upload`, formData, {
        onUploadProgress: (ProgressEvent) => {
          let progress = `${Math.round(
            (ProgressEvent.loaded / ProgressEvent.total) * 100
          )}%`;
          setProgress(progress);
        },
      })
      .then((res) => {
        if (res.data.error === 0) {
          const url = `${API_URL}/public${res.data.path}`;
          setImageURL(url);

          let snippetData = { content: '```\n' + url + '\n```' };

          axios.post(`${API_URL}/snippet/create`, snippetData).then((resp) => {
            if (resp.data.error !== 0) {
              console.log('Failed creating snippet');
              console.log(resp.data);
            }
          });
        } else {
          setFile(null);
          setProgress('0%');
          setImageURL('');
          console.log(res.data);
        }
      });
  };

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center text-2xl bg-gray-800">
      <form
        className="w-screen flex flex-col justify-center items-center"
        onSubmit={(e) => submitImage(e)}
      >
        <div className="w-full text-center font-sans font-bold tracking-widest text-white sm:text-4xl text-xl">
          File Upload
        </div>

        <input
          type="file"
          className="sm:w-3/5 w-5/6 flex justify-center rounded-lg bg-blue-400 p-2 font-mono text-center sm:text-xl text-sm sm:my-8 my-4"
          onChange={(e) => handleUploadImage(e)}
        />

        <button
          className={`py-2 px-4 rounded ${
            file ? 'bg-blue-300 hover:bg-blue-400' : 'bg-gray-900 text-gray-700'
          } sm:w-1/6 w-1/2 font-sans sm:mb-4 mb-2`}
          disabled={file === null}
        >
          Upload
        </button>

        <ProgressBar progress={progress} />

        {imageURL ? (
          <a
            className="rounded bg-green-300 text-blue-800 sm:my-8 my-4 sm:w-3/5 w-5/6 p-2 font-sans sm:text-lg text-sm text-center underline"
            href={imageURL}
          >
            Link to uploaded file
          </a>
        ) : null}

        <img
          src={imageURL}
          alt=""
          style={{ maxHeight: '50vh', maxWidth: '85vw' }}
          className="text-white sm:text-xl text-sm sm:mt-4 mt-2"
        />
      </form>
    </div>
  );
}

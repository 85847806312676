import React from 'react';

export default function ProgressBar({ progress }) {
  return (
    <div className="sm:w-3/5 w-5/6 flex justify-center">
      {progress === '100%' || progress === '0%' ? null : (
        <div className="sm:my-8 my-4 font-extrabold sm:text-2xl text-lg text-blue-300 tracking-wide w-full rounded-lg border-2 border-green-300 text-center py-1">
          {progress}
        </div>
      )}
    </div>
  );
}
